// extracted by mini-css-extract-plugin
export var active = "tree-module--active--lEAzZ";
export var data = "tree-module--data--0wmjB";
export var description = "tree-module--description--VZCwI";
export var image = "tree-module--image--Mx+fa";
export var imageContainer = "tree-module--imageContainer--n8tkV";
export var imageSelector = "tree-module--imageSelector---M7ob";
export var left = "tree-module--left--+KlBS";
export var name = "tree-module--name--SHOf+";
export var section = "tree-module--section--L8l+P";
export var show = "tree-module--show--I306s";
export var smallImage = "tree-module--smallImage--e1x0+";