import React, { useState, useEffect, useContext } from 'react'
import Layout from '../../layout/layout'
import * as styles from './tree.module.scss'
import SharedTextButton from '../../layout/main/components/sharedTextButton'
import { Context } from '../../context'

export default ({ pageContext: { tree: {fields, id}, searchIndexes }}) => {
  const [active, setActive] = useState(0);
  const [show, setShow] = useState(false);
  const [attachments, setAttachments] = useState([])
  const context = useContext(Context);
  const treeImages = context?.treeImages ?? {}

  const button = {
    page: "src/pages/tree-selector.json",
    label: "Back",
    linkType: "page",
    color: "green"
  };

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 100)
  }, [])

  useEffect(() => {
    setAttachments(treeImages[id] ?? [])
  }, [])

  return (
    <Layout title={fields['Common Name']} searchIndexes={searchIndexes}>
      <section className={styles.section}>
        <div className={styles.left + ` ${show ? `${styles.show}` : ''}`}>
          <SharedTextButton button={button} />
          <h2 className={styles.name}>
            {fields['Common Name']}
          </h2>
          <div className={styles.data}>
            <ul className={styles.leftColumn}>
              <li>Scientific name: <strong>{fields['Scientific Name']}</strong></li>
              <li>Tree type: <strong>{fields['Tree Types']}</strong></li>
              <li>Climate: <strong>{fields['Sun']}</strong></li>
              <li>Hardiness zone: <strong>{fields['Hardiness Zone']}</strong></li>
            </ul>
            <ul className={styles.rightColumn}>
              <li>Soil type: <strong>{fields['Soil Types']}</strong></li>
              <li>Height: <strong>{fields['Height (Ft.)']} ft</strong></li>
              <li>Growth rate: <strong>{fields['Growth Rate']}</strong></li>
              <li>Good for: <strong>{fields['Good For']}</strong></li>
            </ul>
          </div>
          <p className={styles.description}>
            {fields['Attributes to write description on']}
          </p>
        </div>
        <div className={styles.imageContainer + ` ${show ? `${styles.show}` : ''}`}>
          {attachments && attachments[active] && attachments[active].thumbnails ? ( 
            <img src={attachments[active] && attachments[active].thumbnails.full.url} className={styles.image} />
          ) : <img src={''} className={styles.image} />}
          {attachments && attachments.length > 1 ? (
          <div className={styles.imageSelector + ` ${show ? `${styles.show}` : ''}`}>
            {attachments.map((attachment, i) => (
              <div className={styles.smallImage + ` ${active === i ? `${styles.active}` : ''}`} key={i}>
                <img
                  src={attachment.thumbnails.large.url}
                  onMouseDown={() => setActive(i)}
                />
              </div>
            ))}
          </div>
        ) : null}
        </div>
      </section>
    </Layout>
  )
}